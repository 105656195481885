export const Role = {
    Admin: 1,
    Supervisor: 2,
    Manager: 3,
    Cashier: 4,
    Collector: 5,
    Auditor: 6,
    Telesales: 7,
    suplyRole: 8,
    salesManager: 9,
}